import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import styles from "../styles/header.module.css"

class Header extends React.Component {
  render() {
    return (
      <Row>
        <Col className={styles.col}>
          <Link className={styles.link} to="/">
            <p className={styles.title}>KATSUYA PLACE</p>
          </Link>
        </Col>
      </Row>
    )
  }
}

export default Header
