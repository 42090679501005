import React from "react"
import PropTypes from "prop-types"

import { Container } from "react-bootstrap"
import "typeface-notosans-jp"
import Header from "./header"
import Category from "./category"

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div>
        <Container>
          <Header />
        </Container>
        <Container>
          <Category />
          {children}
          <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://github.com/katsuya-yamaguchi/ky-blog">
              Katsuya Yamaguchi
            </a>
          </footer>
        </Container>
      </div>
    )
  }
}

export default Layout

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
}
