import React from "react"
import { Row } from "react-bootstrap"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faRegistered,
  faUser,
  faImages,
  faLaughSquint,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons"
import { faDev, faAws } from "@fortawesome/free-brands-svg-icons"
import styles from "../styles/category.module.css"

class Category extends React.Component {
  render() {
    return (
      <Row>
        <div className={styles.category}>
          <ul className={styles.list}>
            <li className={styles.item}>
              <Link className={styles.anker} to="/category/profile">
                <FontAwesomeIcon icon={faLaughSquint} />
                <div className={styles.title}>Profile</div>
              </Link>
            </li>
            <li className={styles.item}>
              <Link className={styles.anker} to="/category/portfolio">
                <FontAwesomeIcon icon={faImages} />
                <div className={styles.title}>Portfolio</div>
              </Link>
            </li>
            <li className={styles.item}>
              <Link className={styles.anker} to="/category/develop">
                <FontAwesomeIcon icon={faDev} />
                <div className={styles.title}>Develop</div>
              </Link>
            </li>
            <li className={styles.item}>
              <Link className={styles.anker} to="/category/develop">
                <FontAwesomeIcon icon={faBriefcase} />
                <div className={styles.title}>Business</div>
              </Link>
            </li>
            <li className={styles.item}>
              <Link className={styles.anker} to="/category/ruby">
                <FontAwesomeIcon icon={faRegistered} />
                <div className={styles.title}>Ruby</div>
              </Link>
            </li>
            <li className={styles.item}>
              <Link className={styles.anker} to="/category/aws">
                <FontAwesomeIcon icon={faAws} />
                <div className={styles.title}>AWS</div>
              </Link>
            </li>
            <li className={`${styles.item} ${styles.itemLast}`}>
              <Link className={styles.anker} to="/category/life">
                <FontAwesomeIcon icon={faUser} />
                <div className={styles.title}>Life</div>
              </Link>
            </li>
          </ul>
        </div>
      </Row>
    )
  }
}

export default Category
